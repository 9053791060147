.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

/* .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
} */

.swiper-wrapper {
    align-items: center;
    margin-bottom: 50px;
}

/* target the active bullet */
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: var(--bio-yellow);
    opacity: 1;
}

/* target all bullets */
.swiper-pagination-bullet {
    background-color: #FFF;
}