.ImgLogoContainer {
    width: 100%;
    height: 100%;
    background-color: #FFF;
    border-radius: 10px;
    padding: 10px;
    margin: 0 10px;
}

.ImgLogo {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 200px;
    aspect-ratio: 3/2;
    object-fit: contain;
    margin: auto;
}